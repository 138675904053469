'use client'

import React from 'react';
import { motion } from 'framer-motion';
import frame39 from '../images/Frame 39.png';
import frame1 from '../images/Frame 1.png';
import frame2 from '../images/Frame 2.png';
import frame3 from '../images/Frame 3.png';
import frame4 from '../images/Frame 4.png';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: 'spring', stiffness: 100 }
  }
};

const faqs = [
  {
    category: 'Getting Started',
    subtopics: [
      { subtopic: 'Sign Up', description: 'How to create an account.' },
      { subtopic: 'Pricing Structure', description: 'Details of pricing tiers.' },
      { subtopic: 'Payment Methods', description: 'Supported payment options.' },
      { subtopic: 'Account Verification', description: 'How to verify your account.' },
      { subtopic: 'Platform Overview', description: 'Introduction to platform features.' },
    ],
  },
];

export default function Personal() {
  return (
    <>
      <div className='bg-[#ffffff] min-h-screen flex items-center'>
        <div className='max-w-7xl mx-auto px-8 sm:px-8 lg:px-16 w-full'>
          <motion.div
            className='py-8'
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <div className="flex flex-col lg:flex-row items-start justify-between">
              <motion.div
                className="w-full lg:w-1/2 lg:pr-8 order-1 lg:order-1 lg:-mt-20"
                variants={itemVariants}
              >
                <div className="text-left">
                  <motion.p
                    className="text-sm font-semibold text-[#000000] mb-2"
                    variants={itemVariants}
                  >
                    Secured abc
                  </motion.p>
                  <motion.h1
                    className="text-2xl font-bold tracking-tight text-black sm:text-4xl"
                    variants={itemVariants}
                  >
                    Instant <br /> your
                  </motion.h1>
                  <motion.div
                    className="mt-10 flex items-center gap-x-6"
                    variants={itemVariants}
                  >
                    <motion.button
                      className="rounded-md bg-[#0060E6] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#0050C2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#0060E6]"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Setup your account
                    </motion.button>
                    <motion.button
                      className="text-sm font-semibold leading-6 text-gray-900"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Revoke Consent
                    </motion.button>
                  </motion.div>

                  <motion.div className="mt-6 flex flex-col items-start" variants={itemVariants}>
                    <div className="text-lg leading-7 text-[#0060E6]">
                      Set up with abc
                    </div>
                    <p className="text-lg leading-7 text-gray-600 mt-2">
                      Setup abc
                    </p>
                  </motion.div>

                  <motion.div className="mt-6 flex flex-col items-start" variants={itemVariants}>
                    <div className="text-lg leading-7 text-[#0060E6]">
                      Data is in your control
                    </div>
                    <p className="text-lg leading-7 text-gray-600 mt-2">
                      Your abc
                    </p>
                  </motion.div>

                  <motion.div
                    className="mt-8 flex justify-start"
                    variants={itemVariants}
                  >
                    <motion.div
                      className="relative px-4 py-4 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20 flex items-center rounded-none"
                      whileHover={{ scale: 1.05, boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)" }}
                    >
                      <img
                        src="https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://example.com"
                        alt="QR Code"
                        className="w-24 h-24 mr-3"
                      />
                      <span className="font-semibold">Scan to setup account</span>
                    </motion.div>
                  </motion.div>
                </div>
              </motion.div>

              <motion.div
                className="w-full lg:w-1/2 lg:pl-8 order-2 lg:order-2 mt-10 lg:mt-0 lg:self-end"
                variants={itemVariants}
              >
                <motion.img
                  src={frame39}
                  alt="Frame 39"
                  className="w-full h-auto"
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Bento Box Section */}
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-center text-base/7 font-semibold text-indigo-600">Deploy faster</h2>
          <p className="mx-auto mt-2 max-w-lg text-center text-4xl font-semibold tracking-tight text-balance text-gray-950 sm:text-5xl">
            Everything you need to deploy your app
          </p>
          <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
            <div className="relative lg:row-span-2">
              <div className="absolute inset-px rounded-lg bg-[#FAFAFA] lg:rounded-l-[2rem]"></div>
              <div className="gap-32 relative flex h-full flex-col overflow-hidden rounded-[calc(var(--radius-lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                <div className="px-8 pt-8 pb-3 sm:px-10 sm:pt-10 sm:pb-0">
                  <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">
                    Mobile friendly
                  </p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                    Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.
                  </p>
                </div>
                <div className="@container relative w-full grow max-lg:mx-auto max-lg:max-w-sm flex justify-center items-center">
                  <img
                    className="object-contain max-h-[400px] w-auto"
                    src={frame1}
                    alt="Mobile friendly"
                  />
                </div>
              </div>
            </div>
            <div className="relative max-lg:row-start-2">
              <div className="absolute inset-px rounded-lg bg-[#FAFAFA] max-lg:rounded-t-[2rem]"></div>
              <div className="gap-8 relative flex h-full flex-col overflow-hidden rounded-[calc(var(--radius-lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
                <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                  <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">Performance</p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit maiores impedit.
                  </p>
                </div>
                <div className="flex flex-1 items-center justify-center px-8 max-lg:pt-10 max-lg:pb-12 sm:px-10 lg:pb-2">
                  <img
                    className="object-contain max-h-[200px] w-auto"
                    src={frame2}
                    alt="Performance"
                  />
                </div>
              </div>
            </div>
            <div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
              <div className="absolute inset-px rounded-lg bg-[#FAFAFA]"></div>
              <div className="gap-8 relative flex h-full flex-col overflow-hidden rounded-[calc(var(--radius-lg)+1px)]">
                <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                  <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">Security</p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                    Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi.
                  </p>
                </div>
                <div className="@container flex flex-1 items-center justify-center max-lg:py-6 lg:pb-2">
                  <img
                    className="object-contain max-h-[200px] w-auto"
                    src={frame3}
                    alt="Security"
                  />
                </div>
              </div>
            </div>
            <div className="relative lg:row-span-2">
              <div className="absolute inset-px rounded-lg bg-[#FAFAFA] max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
              <div className="gap-32 relative flex h-full flex-col overflow-hidden rounded-[calc(var(--radius-lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
                <div className="px-8 pt-8 pb-3 sm:px-10 sm:pt-10 sm:pb-0">
                  <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">
                    Powerful APIs
                  </p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                    Sit quis amet rutrum tellus ullamcorper ultricies libero dolor eget sem sodales gravida.
                  </p>
                </div>
                <div className="relative w-full grow flex justify-center items-center">
                  <img
                    className="object-contain max-h-[400px] w-auto"
                    src={frame4}
                    alt="Powerful APIs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Categories Section */}
      <section className="py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-800">FAQ</h2>
            <p className="mt-2 text-lg text-gray-600">Frequently asked questions</p>
          </div>
          <main className="mx-auto w-full px-6 py-12 lg:px-8">
            <div className="grid grid-cols-1 gap-y-12">
              {faqs.map((faq) => (
                <section key={faq.category} className="flex flex-col space-y-6">
                  <div className="space-y-4">
                    {faq.subtopics.map((subtopic, index) => (
                      <div key={index} className="border-b border-gray-200 pb-4">
                        <details className="group">
                          <summary className="flex items-center cursor-pointer text-gray-700 group-open:text-indigo-600">
                            <span className="ml-4">{subtopic.subtopic}</span>
                          </summary>
                          <p className="mt-2 text-sm text-gray-500 ml-4">{subtopic.description}</p>
                        </details>
                      </div>
                    ))}
                  </div>
                </section>
              ))}
            </div>
          </main>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-[#FAFAFA] py-6 mt-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
          <p className="text-sm text-gray-500">Have further questions? <a href="#" className="text-indigo-600 hover:underline">Contact us</a>.</p>
        </div>
      </footer>
    </>
  )
}
