'use client'

import React from 'react';
import { motion } from 'framer-motion';
import frame44 from '../images/Frame 44.png';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: 'spring', stiffness: 100 }
  }
};

const BentoBox = ({ title, description }) => {
  return (
    <motion.div
      className="w-full h-[550px] relative bg-[#FAFAFA] rounded-[20px] cursor-pointer"
      whileHover={{ scale: 1.02 }}
    >
      <motion.div 
        className="w-full h-full absolute bg-[#FAFAFA] rounded-[20px] overflow-hidden"
      >
        <a href="https://google.com" target="_blank" rel="noopener noreferrer">
          <motion.div 
            className="right-[25px] top-[25px] absolute"
            initial={{ rotate: 0 }}
            whileHover={{ rotate: 90, transition: { duration: 0.3 } }}
          >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="10" fill="#D1D5DB"/>
              <path d="M22.7504 10V19.75C22.7504 19.9489 22.6714 20.1397 22.5307 20.2803C22.3901 20.421 22.1993 20.5 22.0004 20.5C21.8015 20.5 21.6107 20.421 21.4701 20.2803C21.3294 20.1397 21.2504 19.9489 21.2504 19.75V11.8103L10.531 22.5306C10.3903 22.6714 10.1994 22.7504 10.0004 22.7504C9.80139 22.7504 9.61052 22.6714 9.46979 22.5306C9.32906 22.3899 9.25 22.199 9.25 22C9.25 21.801 9.32906 21.6101 9.46979 21.4694L20.1901 10.75H12.2504C12.0515 10.75 11.8607 10.671 11.7201 10.5303C11.5794 10.3897 11.5004 10.1989 11.5004 10C11.5004 9.80109 11.5794 9.61032 11.7201 9.46967C11.8607 9.32902 12.0515 9.25 12.2504 9.25H22.0004C22.1993 9.25 22.3901 9.32902 22.5307 9.46967C22.6714 9.61032 22.7504 9.80109 22.7504 10Z" fill="white"/>
            </svg>
          </motion.div>
        </a>

        <div className="w-[calc(100%-50px)] left-[25px] top-[305px] absolute">
          <h3 className="text-2xl font-semibold tracking-tight text-gray-900 mb-4">{title}</h3>
          <p className="text-base text-gray-700 leading-relaxed">{description}</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

const features = [
  {
    title: "Mobile friendly",
    description: "Build responsive and adaptive applications that work seamlessly across all devices and screen sizes."
  },
  {
    title: "Performance",
    description: "Optimize your application's speed and efficiency with our high-performance infrastructure and tools."
  },
  {
    title: "Security",
    description: "Implement robust security measures and protect your data with enterprise-grade security features."
  }
];

export default function Personal() {
  return (
    <>
      <div className="bg-white min-h-screen flex items-center">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 w-full">
          <motion.div
            className="py-8"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <div className="flex flex-col lg:flex-row items-start justify-between">
              <motion.div
                className="w-full lg:w-1/2 lg:pr-8"
                variants={itemVariants}
              >
                <div className="text-left">
                  <motion.p className="text-sm font-semibold text-[#0060E6] mb-2" variants={itemVariants}>
                    Secured abc
                  </motion.p>
                  <motion.h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl" variants={itemVariants}>
                    Instant <br /> your
                  </motion.h1>
                  <motion.p className="mt-4 text-lg text-gray-700" variants={itemVariants}>
                    Revolutionize your workflow and boost productivity with instant, secure solutions.
                  </motion.p>
                  <div className="mt-6 flex space-x-4">
                    <motion.a
                      href="#"
                      className="inline-block px-6 py-3 text-white bg-[#0060E6] rounded-md text-lg font-semibold hover:bg-blue-700"
                      variants={itemVariants}
                    >
                      Get Started
                    </motion.a>
                    <motion.a
                      href="#"
                      className="inline-block px-6 py-3 text-[#0060E6] border-2 border-[#0060E6] rounded-md text-lg font-semibold hover:bg-[#0060E6] hover:text-white"
                      variants={itemVariants}
                    >
                      Learn More
                    </motion.a>
                  </div>
                </div>
              </motion.div>

              <motion.div className="w-full lg:w-1/2 lg:pl-8" variants={itemVariants}>
                <motion.img
                  src={frame44}
                  alt="Frame 44"
                  className="w-full h-auto"
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-base font-semibold leading-7 text-[#0060E6]">Deploy faster</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 text-center sm:text-4xl">
            Everything you need to deploy your app
          </p>
          <div className="mt-16 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.title} className="w-full">
                <BentoBox title={feature.title} description={feature.description} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
