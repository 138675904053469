'use client';

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Dialog,
  DialogPanel,
} from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

const products = [
  { name: 'Individual', href: '/individual' },
  { name: 'Business', href: '/business' },
  { name: 'About', href: '/company' },
  { name: 'Developers', href: '/developers' },
];

const Navbar = () => {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const buttonStyles = {
    base: "block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50",
    contactSalesBg: "bg-[#0027B3] hover:bg-[#1a3ebe] text-white",
    signInBorder: "border border-[#D3D9DF]",
  };

  return (
    <header className="bg-white">
      <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
        {/* Logo and Navigation Links */}
        <div className="flex flex-1 items-center">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Abc</span>
            <span className="text-l font-sans font-bold text-[#000000] truncate">Abc</span>
          </Link>
          <div className="hidden lg:flex ml-32 gap-x-8">
            {products.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="text-sm font-semibold text-gray-900"
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>

        {/* Mobile Menu Button */}
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {/* Sign in and Contact Sales Buttons */}
        <div className="hidden lg:flex lg:flex-none gap-x-4">
          <Link to="/signin" className={`text-sm font-semibold text-gray-900 px-4 py-2 rounded-md ${buttonStyles.signInBorder}`}>
            Sign in
          </Link>
          <Link to="/contact-sales" className={`text-sm font-semibold ${buttonStyles.contactSalesBg} px-4 py-2 rounded-md`}>
            Contact Sales
          </Link>
        </div>
      </nav>

      {/* Mobile Menu */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Abc</span>
              <span className="text-l font-sans font-bold text-[#000000] truncate">Abc</span>
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              {/* Mobile Navigation Links */}
              <div className="space-y-2 py-6">
                {products.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              {/* Sign in and Contact Sales Buttons for Mobile */}
              <div className="py-6 space-y-4">
                <Link
                  to="/signin"
                  className={`${buttonStyles.base} ${buttonStyles.signInBorder}`}
                >
                  Sign in
                </Link>
                <Link
                  to="/contact-sales"
                  className={`${buttonStyles.base} ${buttonStyles.contactSalesBg}`}
                >
                  Contact Sales
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default Navbar;
