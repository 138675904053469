import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa'; // Importing LinkedIn icon from react-icons

const Footer = () => {
  return (
    <footer className="w-full bg-[#ffffff] py-12">
      <div className="mx-auto max-w-7xl px-8 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-12">
          <div className="lg:col-span-2 space-y-8">
            <div className="flex flex-col justify-start space-y-4">
              <span className="text-lg font-bold text-gray-900">
                Get started with apay today.
              </span>
              <span className="text-sm font-medium text-gray-500">
                To advance a more secure, efficient, and integrated online interbank payments network.
              </span>
            </div>
            {/* Adding LinkedIn icon */}
            <div className="mt-4">
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={24} className="text-gray-600 hover:text-gray-900" />
              </a>
            </div>
          </div>

          <div className="lg:col-start-4 space-y-6">
            <h4 className="text-lg font-medium text-gray-900">Home</h4>
            <ul className="space-y-4">
            <li><Link to="/individual" className="text-sm text-gray-600 hover:text-gray-900">Individuals</Link></li>
              <li><Link to="/business" className="text-sm text-gray-600 hover:text-gray-900">Business</Link></li>
              <li><Link to="/company" className="text-sm text-gray-600 hover:text-gray-900">Company</Link></li>
              <li><Link to="/resources" className="text-sm text-gray-600 hover:text-gray-900"> Develoeprs</Link></li>
            </ul>
          </div>

          <div className="lg:col-start-5 space-y-6">
            <h4 className="text-lg font-medium text-gray-900">Legal</h4>
            <ul className="space-y-4">
              <li><Link to="/news" className="text-sm text-gray-600 hover:text-gray-900">End User Terms</Link></li>
              <li><Link to="/tips-tricks" className="text-sm text-gray-600 hover:text-gray-900"> Merchant Terms </Link></li>
              <li><Link to="/tips-tricks" className="text-sm text-gray-600 hover:text-gray-900">Privacy Policy</Link></li>
              <li><Link to="/tips-tricks" className="text-sm text-gray-600 hover:text-gray-900">Contact</Link></li>
            </ul>
          </div>
        </div>
        <div className="mt-12 text-xs py-8 text-gray-400">
          © 2025 APAY Technologies ApS. All rights reserved. CVR 12345678. Tuborg Havnevej 16, 2900 Hellerup, Denmark.
          Registered at Payment services provided by Payment Institution regulated by the German Financial Supervisory Authority (BAFIN) with identification.
          APay is a technology company, not a licensed financial institution. All names are trademarks™ or registered trademarks® of their respective holders.
          Their usage does not imply any affiliation with or endorsement by their holders.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
